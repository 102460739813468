import React, { Component } from 'react'
import '../App.css'
import Dropdown from './dropdown';

class Education extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.containerRef = React.createRef()
    }
    
    render() {
        return (
            <ul ref={this.containerRef} className='education-list'>
                <Dropdown
                header={[{
                    text: "B.S. ",
                    bold: true
                },{
                    text: "in ",
                    bold: false
                }, {
                    text: "Computer Science",
                    bold: true
                }]}
                subheader="University of Maryland, Baltimore County"
                details={[
                    "I completed my Bachelor’s of Science in 2022 from the University of Maryland, Baltimore County. (Go Retrievers!)",
                    "I completed and passed notable classes such as Data Structures, Algorithms, Computer Networking, Parallel and Distributed Processing, and many others.",
                    "I led and participated in many clubs and activities such as the National Society of Black Engineers, UMBC CyberDawgs, Computer Science Education club, and many others."
                ]}
                />
                <Dropdown
                header={[{
                    text: "M.S. ",
                    bold: true
                },{
                    text: "in ",
                    bold: false
                }, {
                    text: "Cybersecurity",
                    bold: true
                }]}
                subheader="University of Maryland, Baltimore County"
                details={[
                    "I am currently in my second year, pursuing a Master's of Science in Cybersecurity at the University of Maryland, Baltimore County",
                    "Leaning more into cyber, I started taking classes like Digital Forensics, and Cyber Law and Policy to broaden my knowledge of technology",
                    "I participate and am active in the UMBC Protocol Analysis Lab under the leadership of Ph.D student Enis Golaszewski."
                ]}
                />
                <Dropdown
                header={[{
                    text: "Security+ ",
                    bold: true
                },{
                    text: "Certification",
                    bold: false
                }]}
                subheader="CompTIA"
                details={[
                    "In March of 2022, I took and passed the CompTia Security+ Certification on my first attempt.",
                    "I spent 3 weeks of studying for 8 hours every single day in order to ensure my success."
                ]}
                />
            </ul>
        )
    }
}

export default Education