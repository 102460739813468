import React, { Component } from 'react'
import '../App.css'

class PricingTier extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className='service-item'>
                <div className='service-item-title-container'>
                    <h1 className='service-item-title'>{this.props.tier}</h1>
                </div>
                <div className='service-item-content-container'>

                    <ul className='service-item-content'>
                        {this.props.services.split("\n").map((e, i) => {
                            return (
                                <li className='service-item-list-item' key={i}>{e}</li>
                            )

                        })}

                    </ul>
                    <h3 className='service-item-price'>{this.props.priceRange}</h3>
                </div>
            </div>
        )
    }
}

export default PricingTier