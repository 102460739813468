import React, { Component } from 'react'
import '../App.css'

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            subject: "",
            message: ""

        }
        this.succRef = React.createRef();
        this.errRef = React.createRef();
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let requestData = { email: this.state.email, subject: this.state.subject, message: this.state.message,}
            const response = await fetch('/api/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                this.errRef.current.style.display = "flex";
                throw new Error('Network response was not ok');
            }
            this.succRef.current.style.display = "flex";
            this.errRef.current.style.display = "none";
            this.setState({ name: '', subject: '', message: '' });
            
        } catch(err) {
            console.log(err)
            this.errRef.current.style.display = "flex";
        }
    };

    render() {
        return (
            <section id='contact' className='contact-container'>
                <h1 className='contact-header'>Contact</h1>
                <form className='message-container' onSubmit={this.handleSubmit}>
                    <h3 className='message-header'>Let's Talk!</h3>
                    <div className='message-name'>
                        <input
                            className='message-name-input'
                            type="text"
                            id="name"
                            name="name"
                            placeholder='Email'
                            value={this.state.name}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <div className='message-subject'>
                        <input
                            className='message-subject-input'
                            type="text"
                            id="subject"
                            name="subject"
                            placeholder='Subject'
                            value={this.state.subject}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className='message-message'>
                        <textarea
                            className='message-message-input'
                            type="text"
                            id="message"
                            name="message"
                            placeholder='What would you like to tell me?'
                            value={this.state.message}
                            onChange={this.handleChange}
                            rows={10}
                            required
                        />
                    </div>
                    <div ref={this.errRef} className='status-message-container error-message-container'>
                        Something went wrong. Please try again.
                    </div>
                    <div ref={this.succRef} className='status-message-container success-message-container'>
                        Message successfully sent!
                    </div>
                    <button className='message-button' type="submit">Submit</button>
                </form>
            </section>
        )
    }
}

export default Contact
