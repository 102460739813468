import React, { Component } from 'react'
import '../App.css'
import Dropdown from './dropdown';

class Skills extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.containerRef = React.createRef();
    }

    render() {
        return (
            <div ref={this.containerRef} className='skills-list'>
                <ul className='skills-sublist'>

                    <Dropdown
                        header={[{ text: "Programming", bold: true }]}
                        subheader=""
                        details={["Javascript", "ReactJS", "AWS", "MongoDB", "Python", "Golang", "C/C++"]}
                        half={true}
                    ></Dropdown>
                    <Dropdown
                        header={[{ text: "Web Design", bold: true }]}
                        subheader=""
                        details={["Figma", "Adobe Illustrator", "Adobe Photoshop"]}
                        half={true}
                    ></Dropdown>
                    <Dropdown
                        header={[{ text: "Offensive Security", bold: true }]}
                        subheader=""
                        details={["Metasploit", "Nessus", "Burp Suite", "Kali Linux", "Binary Exploitaiton"]}
                        half={true}
                    ></Dropdown>
                </ul>
                <ul className='skills-sublist'>
                    <Dropdown
                        header={[{ text: "Defensive Security", bold: true }]}
                        subheader=""
                        details={["Splunk", "Wireshark", "Snort", "Digital Forensics"]}
                        half={true}
                    ></Dropdown>
                    <Dropdown
                        header={[{ text: "Software", bold: true }]}
                        subheader=""
                        details={["VS Code", "Microsoft Office", "Adobe", "Google Suite"]}
                        half={true}
                    ></Dropdown>
                    <Dropdown
                        header={[{ text: "Miscellaneous", bold: true }]}
                        subheader=""
                        details={["Git", "CMS", "SEO", "MongoDB"]}
                        half={true}
                    ></Dropdown>
                </ul>
            </div>
        )
    }
}

export default Skills
