import React, { Component } from 'react'
import '../App.css'
import Experience from './experience';
import Education from './education';
import Skills from './skills';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: "experience"
        }
        this.expRef = React.createRef()
        this.eduRef = React.createRef()
        this.sklRef = React.createRef()
        this.navRef1 = React.createRef()
        this.navRef2 = React.createRef()
        this.navRef3 = React.createRef()
        this.switchToEducation = this.switchToEducation.bind(this)
        this.switchToExperience = this.switchToExperience.bind(this)
        this.switchToSkills = this.switchToSkills.bind(this)
        this.makeInvisible = this.makeInvisible.bind(this)
        this.makeVisible = this.makeVisible.bind(this)
        this.animationSpeed = 300;
    }

    componentDidMount() {
        this.navRef1.current.classList.add("about-nav-item-selected")
        this.expRef.current.containerRef.current.style.display = "flex"
        this.expRef.current.containerRef.current.classList.add("active-list")
        this.eduRef.current.containerRef.current.style.display = "none"
        this.eduRef.current.containerRef.current.classList.remove("active-list")
        this.sklRef.current.containerRef.current.style.display = "none"
        this.sklRef.current.containerRef.current.classList.remove("active-list")
    }

    makeVisible(ref, style) {
        if (ref.current) {
            ref.current.style.display = style  
            setTimeout(() => {
                ref.current.classList.add("active-list") 
            }, this.animationSpeed);
        }
    }

    makeInvisible(ref) {
        if (ref.current) {
            ref.current.classList.remove("active-list")
            setTimeout(() => {
                ref.current.style.display = "none"
            }, this.animationSpeed);
        }
    }

    switchToExperience() {
        this.navRef1.current.classList.add("about-nav-item-selected")
        this.navRef2.current.classList.remove("about-nav-item-selected")
        this.navRef3.current.classList.remove("about-nav-item-selected")
        this.makeInvisible(this.eduRef.current.containerRef)
        this.makeInvisible(this.sklRef.current.containerRef)
        setTimeout(() => {
            this.makeVisible(this.expRef.current.containerRef, "flex")
        }, this.animationSpeed);
    }
    switchToEducation() {
        this.navRef2.current.classList.add("about-nav-item-selected")
        this.navRef1.current.classList.remove("about-nav-item-selected")
        this.navRef3.current.classList.remove("about-nav-item-selected")
        this.makeInvisible(this.expRef.current.containerRef)
        this.makeInvisible(this.sklRef.current.containerRef)
        setTimeout(() => {
            this.makeVisible(this.eduRef.current.containerRef, "flex")
        }, this.animationSpeed);
    }
    switchToSkills() {
        this.navRef3.current.classList.add("about-nav-item-selected")
        this.navRef2.current.classList.remove("about-nav-item-selected")
        this.navRef1.current.classList.remove("about-nav-item-selected")
        this.makeInvisible(this.expRef.current.containerRef)
        this.makeInvisible(this.eduRef.current.containerRef)
        setTimeout(() => {
            this.makeVisible(this.sklRef.current.containerRef, "flex")
        }, this.animationSpeed);
    }
    // TODO: Fix above function by passing change function to children, then calling those functions from up here.

    render() {
        return (
            <section id='about' className='about-container'>
                <h1 className='about-header'>About Me</h1>
                <ul className='about-nav-list'>
                    <li ref={this.navRef1} onClick={this.switchToExperience} className='about-nav-item'>Experience</li>
                    <li ref={this.navRef2} onClick={this.switchToEducation} className='about-nav-item'>Education</li>
                    <li ref={this.navRef3} onClick={this.switchToSkills} className='about-nav-item'>Skills</li>
                </ul>
                <div className='about-body-container'>
                    <Experience ref={this.expRef}></Experience>
                    <Education ref={this.eduRef}></Education>
                    <Skills ref={this.sklRef}></Skills>
                </div>
            </section>
        )
    }
}

export default About