import React, { Component, createRef } from 'react'
import '../App.css'

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
        this.plusRef = React.createRef();
        this.minusRef = React.createRef();
        this.dropdownRef = React.createRef();
        this.toggleSVG = this.toggleSVG.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleItems = this.toggleItems.bind(this);
    }

    componentDidMount() {
    }
    toggleSVG() {
        this.minusRef.current.classList.toggle("svg-show")
        this.plusRef.current.classList.toggle("svg-show")
    }

    toggleDropdown() {
        this.dropdownRef.current.classList.toggle("show-dropdown-list")

    }

    toggleItems() {
        this.toggleSVG();
        this.toggleDropdown();
    }

    render() {
        return (
            <li className={this.props.half ? "about-item skills-item" : "about-item"}>
                <div onClick={this.toggleItems} className='about-item-header-container'>
                        <div className='about-item-plus-container'>
                            <svg ref={this.plusRef} className='about-svg svg-show' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                            <svg ref={this.minusRef} className='about-svg' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-440v-80h560v80H200Z"/></svg>
                        </div>
                        <div className='about-item-text-container'>
                            <p className='about-item-header'>
                                {this.props.header.map((ele, ind) => (
                                    ele.bold ? <strong>{ele.text}</strong> : <span>{ele.text}</span>
                                ))}
                            </p>
                            {this.props.subheader == "" ? (<p className='about-item-tag' style={({display: "none"})}>{this.props.subheader}</p>) : (<p className='about-item-tag'>{this.props.subheader}</p>) }
                        </div>
                    </div>
                <ul ref={this.dropdownRef} className='about-item-dropdown-container'>
                    {this.props.details.map((element, index) => (
                        <li key={index} className='about-item-dropdown-item'>{element}</li>
                    ))}
                </ul>
            </li>
        )
    }
}

export default Dropdown