import React, {Component} from 'react'
import '../App.css'

class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: ["Web Developer", "Graphic Designer", "Penetration Tester", "Software Engineer"],
            currentIndex: 0
        }
        this.intervalId = null
    }

    componentDidMount() {
        this.intervalId = setInterval(this.cycleOptions, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId)
    }
    
    cycleOptions = () => {
        console.log(this.state.currentIndex)
        this.setState({currentIndex: (this.state.currentIndex + 1) % this.state.options.length}, () => {        
            console.log(this.state.options[this.state.currentIndex])    
        })
    }

    downloadFile() {
        
    }

    render() {
        return (
            <section  id='home' className='hero-container'>
                <div className='hero-text-container'>
                    <div className='hero-header-section'>
                        <h3 className='hero-header1'>My name is Neil. I'm a </h3>
                        <h1 className='hero-header2'>{this.state.options[this.state.currentIndex]}</h1>
                        <p className='hero-header3'>based in Silver Spring, Maryland</p>
                    </div>
                    <div className='hero-subheader-section'>
                        <h4 className='hero-subheader'>I create technology solutions to help your business grow</h4>
                    </div>
                    <div className='hero-button-container'>
                        <a href="/resume" className='hero-button hero-button-CV' on>Download CV</a>
                        <a href='#contact' className='hero-button hero-button-contact'>Contact Me</a>
                    </div>
                </div>
                <div className='hero-image-container'>
                    <img src='/images/HeadshotSquare.png'alt='headshot' className='hero-image'></img>
                    <svg className="hero-image-svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M410-120v-238L204-239l-70-121 206-120-206-119 70-121 206 119v-239h140v239l206-119 70 121-206 119 206 120-70 121-206-119v238H410Z"/></svg>
                </div>
            </section>
        )
    }
}

export default Hero