import React, { Component } from 'react'
import '../App.css'
import ServiceItem from './serviceItem';
import SecuritySVG from './SVGComponents/SecuritySVG';
import WebSVG from './SVGComponents/WebSVG';
import BusinessSVG from './SVGComponents/BusinessSVG'
import ComputerSVG from './SVGComponents/ComputerSVG'

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["Tier I", "Tier II", "Tier III"],
            services: ["Static website development and deployment. (1-5 Pages)\nLarge-scale data entry with custom scripts and programs\nWebsite maintenance and update\nCustom automation script and tooling development",
                "Dynamic web site development \nAPI Development and Integration\nCloud deployment and management\nAdvanced Automation and Scripting\nApplication security auditing and hardening",
                "Full stack dynamic website with enterprise-level architecture\nMobile App development for iOS and Android.\nCustom software development and integration\nLarge scale code refactoring and modernization"
            ],
            priceRanges: ["$25 - $200", "$150 - $500", "$500+"]
        }
    }

    render() {
        let {headers} = this.state
        return (
            <section  id='services' className='services-container'>
                <h1 className='services-header'>Services</h1>
                <div className='services-list-container'>
                    {headers.map((ele, ind) => (
                        <ServiceItem tier={ele} services={this.state.services[ind]} priceRange={this.state.priceRanges[ind]}></ServiceItem>
                    ))}
                </div>
            </section>
        )
    }
}

export default Services