import React, {Component} from 'react'
import '../App.css'

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.navRef = React.createRef();
        this.blockRef = React.createRef();
    }

    hideNavbar = () => {
        console.log(this.navRef)
        if (this.navRef.current){
            this.navRef.current.style.left = "-100%"
            this.blockRef.current.style.display = "none"
        }
    }

    showNavBar = () => {
        console.log(this.navRef)
        if (this.navRef.current){
            this.blockRef.current.style.display = "block"
            this.navRef.current.style.left = "0"
        }
    }

    render() {
        return (
            <nav id='navbar' className='navbar-container'>
                <div className='nav-modem' ref={this.blockRef}></div>
                <div onClick={this.showNavBar} className='nav-menu-button-container'>
                <svg className='nav-menu-button' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg>
                </div>
                <div className='logo-container'>
                    <span className='logo-text'><a href='#'>MZB-DEV</a></span>
                </div>
                <ul ref={this.navRef}  className='nav-item-container'>
                    <div onClick={this.hideNavbar} className='nav-close-button-container'>
                        <svg className='nav-close-button' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                    </div>
                    <a onClick={this.hideNavbar} href='#home' className='nav-item'>Home</a>
                    <a onClick={this.hideNavbar} href='#about' className='nav-item'>About</a>
                    <a onClick={this.hideNavbar} href='#services' className='nav-item'>Services</a>
                    <a onClick={this.hideNavbar} href='#contact' className='nav-item'>Contact</a>
                </ul>
                <div className='nav-button-container'>
                    <a href='/resume' className='nav-button'>Download CV</a>
                </div>
            </nav>
        )
    }
}

export default Navbar