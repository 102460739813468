import React, {Component} from 'react'
import '../App.css'
import Dropdown from './dropdown';

class Experience extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.containerRef = React.createRef();
    }
    
    render() {
        return (
            <ul ref={this.containerRef} className='experience-list'>
                <Dropdown
                header={[{
                    text: "2 ",
                    bold: true
                },{
                    text: "Years at ",
                    bold: false
                },{
                    text: "EmpowerAI",
                    bold: true
                }]}

                subheader="Full Stack Web Developer"
                details={[
                    "From May 2022 I was contracted with the Air National Guard to help build, design and maintain their complex web and cloud applications.",
                    "I Collaborated with a team of 10+ people where we communicated to effectively solve problems and overcome hurdles",
                    "I developed software using C#, Blazor, and .NET to build our front-end systems and integrated them with the SQL database."
                ]}
                />
                <Dropdown
                header={[{
                    text: "4 ",
                    bold: true
                },{
                    text: "Years at ",
                    bold: false
                },{
                    text: "N4Weddings",
                    bold: true
                }]}

                subheader="System Administrator"
                details={[
                    "I single-handedly designed and maintained the technical systems that support business operations at N4weddings",
                    "I designed and maintained the MySQL database which stored hundreds of customer and transaction data",
                    "I built systems and tools to easily integrate existing invoice application data into SQL entries to ensure ease of continued maintenance"
                ]}
                />
                <Dropdown
                header={[{
                    text: "3+ ",
                    bold: true
                },{
                    text: "Years at ",
                    bold: false
                },{
                    text: "My House",
                    bold: true
                }]}

                subheader="Freelance Web Developer"
                details={[
                    "I communicated with clients to build web and software solutions for their business problems and tasks.",
                    "I often utilized tools such as ReactJS, ExpressJS, PostgresSQL and MongoDB in order to build full-stack applications to support my client's needs",
                    "I worked closely with each client to ensure that my solution matched with their needs exactly."
                ]}
                />
            </ul>
        )
    }
}

export default Experience